import { Fragment, useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { WizardStepsContextProvider } from '@steelbuy/ui-primitive';
import { useRerenderFromNavbar } from '@steelbuy/util';
import { RFQ_STEPS } from '../../components/create-rfq/createRFQUtil';
import { RfqFormProvider, useRfqFormContext } from '../../components/create-rfq/RfqFormContext';

const CreateRFQViewInner = () => {
    const location = useLocation();
    const createRFQData = location.state?.createRFQData;
    const { updateFormData } = useRfqFormContext();
    const [isInitialDataSet, setIsInitialDataSet] = useState(!createRFQData);

    useEffect(() => {
        if (createRFQData) {
            updateFormData(createRFQData);
            setIsInitialDataSet(true);
        }
    }, [createRFQData]);

    if (!isInitialDataSet) {
        return null;
    }

    return <Outlet />;
};

export const CreateRFQView = () => {
    const rerenderKey = useRerenderFromNavbar();

    return (
        <Fragment key={rerenderKey}>
            <RfqFormProvider>
                <WizardStepsContextProvider steps={RFQ_STEPS}>
                    <CreateRFQViewInner />
                </WizardStepsContextProvider>
            </RfqFormProvider>
        </Fragment>
    );
};
