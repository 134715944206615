import { useTranslation } from 'react-i18next';
import { useIsFeatureSupported, useUserSelf, userHasRole } from '@steelbuy/data-provider';
import {
    Feature,
    ListingBuyerModel,
    OfferListingStatus,
    OfferView,
    PackageBuyerModel,
    SearchFormData,
    UserRole,
    getPricingUnit,
    isStackableProduct,
} from '@steelbuy/domain-model';

import { MonetaryAmount, createDateFromTimestamp } from '@steelbuy/domain-model-types';

import {
    ButtonSize,
    ButtonTertiary,
    ButtonTertiaryOnDarkS,
    ButtonTertiaryOnLightM,
    LoadingStatus,
} from '@steelbuy/ui-primitive';
import { DaynameFormat, formatDayname, formatTime } from '@steelbuy/util';
import { ListingTeaser } from './ListingTeaser';

import { ListingTeaserContainer } from './ListingTeaserContainer';
import { ListingTeaserHeader } from './ListingTeaserHeader';
import { ListingTeaserPrice } from './ListingTeaserPrice';
import { ListingTeaserWeightAndItems } from './ListingTeaserWeightAndItems';
import { useNumberOfItems } from '../number-of-items-label/NumberOfItemsLabel';

import './ListingTeaser.scss';
import { PackageTeaserHeader } from '../package-teaser/PackageTeaserHeader';

type ListingProps = { isPackage?: false; listing: ListingBuyerModel } | { isPackage: true; listing: PackageBuyerModel };

export type ListingBuyerTeaserProps = ListingProps & {
    searchData?: SearchFormData;
    isNew?: boolean;
    offerStatus?: OfferListingStatus;
    numberOffers?: number;
    numberActiveOffers?: number;
    offerPrice?: MonetaryAmount;
    referenceId?: string;
    offerValue?: number;
    minActiveOffersForBadge?: number;
    onBuyClick?: () => void;
    onClickCartButton?: () => void;
    isLoading?: boolean;
    isCart?: boolean;
};

export const ListingBuyerTeaser = ({
    isNew,
    listing,
    searchData,
    offerStatus,
    numberOffers,
    numberActiveOffers,
    offerPrice,
    referenceId,
    isPackage,
    offerValue,
    minActiveOffersForBadge,
    onBuyClick,
    isCart = false,
    onClickCartButton,
    isLoading = false,
}: ListingBuyerTeaserProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const numberOfItems = useNumberOfItems(
        (listing as ListingBuyerModel).material.product,
        (listing as ListingBuyerModel).numberOfItems
    );

    const userModel = useUserSelf().get();
    const hasBuyerRole = userHasRole(userModel, [UserRole.BUYER]);
    const showCart = useIsFeatureSupported(Feature.CART) && hasBuyerRole;

    const noOfItemsText =
        !isPackage && isStackableProduct(listing.material.product)
            ? { label: listing.material.product, value: listing.numberOfItems ?? 0 }
            : undefined;

    const expirationDate = createDateFromTimestamp(listing.expiresAt);

    const pricingUnit = getPricingUnit(!isPackage ? listing.material : { materialType: listing.material });

    const originTranslations = Array.isArray(listing.origin)
        ? listing.origin.map((text) => t(`domainModel:material.origin.value.${text}`)).join(', ')
        : t(`domainModel:material.origin.value.${listing.origin}`);

    const ageTranslation = isPackage ? undefined : t(`domainModel:material.age.value.${listing.age}`);

    return (
        <ListingTeaser threeColumns={isCart}>
            {isPackage ? (
                <PackageTeaserHeader packages={listing} isNew={isNew} />
            ) : (
                <ListingTeaserHeader listing={listing} searchData={searchData} isNew={isNew} />
            )}
            <ListingTeaserContainer
                isPrime={listing.prime}
                expiryTime={`Exp: ${formatDayname(
                    navigator.language,
                    expirationDate,
                    undefined,
                    DaynameFormat.SHORT
                )} ${formatTime(navigator.language, expirationDate)}`}
                deliveryTime={t(
                    `domainModel:deliveryPromise.${!isPackage ? listing.deliveryPromise : listing.deliveryTimeframe}`
                )}
                offerStatus={offerStatus}
                numberOffers={numberOffers}
                numberActiveOffers={numberActiveOffers}
                referenceId={referenceId}
                offerView={OfferView.BUYER}
                minActiveOffersForBadge={minActiveOffersForBadge}
                countryCode={listing.country}
                cartValues={
                    isCart
                        ? {
                              weight: { value: listing.weight, tradeUnit: listing.tradeUnit },
                              noOfItems: noOfItemsText,
                              origin: originTranslations,
                              age: ageTranslation,
                          }
                        : undefined
                }
            />
            {!isCart && (
                <ListingTeaserWeightAndItems
                    weight={listing.weight}
                    unit={listing.tradeUnit}
                    numberOfItems={numberOfItems}
                />
            )}
            <ListingTeaserPrice
                value={offerPrice || listing.pricing.priceIncludingDelivery}
                currencyCode={listing.pricing.currencyCode}
                unit={pricingUnit}
                isNew={isNew}
                isMyOffer={!!offerStatus}
                offerValue={offerValue}
                onBuyClick={onBuyClick}
                showTotal={isCart ? listing.pricing.totalExcludingVat : undefined}
            >
                {isCart && (
                    <div className="cart-remove-button">
                        <ButtonTertiary
                            label={t('uiDomain:common.remove')}
                            size={ButtonSize.S}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </div>
                )}
                {showCart && !isCart && (!!onClickCartButton || !!onBuyClick) && (
                    <div className="listing-teaser__buttons">
                        <ButtonTertiaryOnLightM
                            label={t(`uiDomain:commonList.addToCart`)}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onClickCartButton?.();
                            }}
                            loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                        />
                        {onBuyClick && (
                            <ButtonTertiaryOnDarkS
                                label={t(`uiDomain:commonList.buyNow`)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBuyClick?.();
                                }}
                            />
                        )}
                    </div>
                )}
            </ListingTeaserPrice>
        </ListingTeaser>
    );
};
