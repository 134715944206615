import { Trans, useTranslation } from 'react-i18next';
import './ActFastNotification.scss';
import { IconIdentifier, Notification, NotificationLevel } from '@steelbuy/ui-primitive';

const TimerNotification = ({ expiresAt }: { expiresAt?: string }) => {
    const { t } = useTranslation(['translation', 'domainModel']);
    return (
        <div className="notification__main-content__add-to-cart-content">
            <span className="notification__main-content__message">
                {expiresAt && (
                    <Trans
                        i18nKey="application.addToCartConfirmModal.actFast"
                        components={{
                            time: <span className="notification__main-content__add-to-cart-content__time" />,
                        }}
                        t={t}
                        values={{ time: '00:00' }}
                    />
                )}
            </span>
            <span className="notification__main-content__message">
                {t('application.addToCartConfirmModal.itemsNotReserved')}
            </span>
        </div>
    );
};

export const ActFastNotification = ({ expiresAt }: { expiresAt?: string }) => (
    <div className="act-fast-notification">
        <Notification
            level={NotificationLevel.INFO}
            icon={IconIdentifier.TIMER}
            Component={<TimerNotification expiresAt={expiresAt} />}
            message=""
            stayOpen
        />
    </div>
);
