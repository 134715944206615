import { zodResolver } from '@hookform/resolvers/zod';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { isLoginSuccess, login, resendWelcomeEmail, Tenant } from '@steelbuy/api-integration';
import { useAuth } from '@steelbuy/auth';
import { isAccessDeniedError, isRateLimitError } from '@steelbuy/error';
import {
    ButtonCallToAction,
    CustomLink,
    FormActionbar,
    FormItem,
    Icon,
    IconIdentifier,
    InputTextfield,
    LoadingStatus,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { InputPassword } from '../input-password/InputPassword';
import './LoginForm.scss';

export enum LoginError {
    INCORRECT_CREDENTIALS = 1,
    TEMP_PASSWORD_EXPIRED,
    SERVER_ERROR,
    TIMEOUT,
    UNAUTHORIZED,
    RATE_LIMIT,
}

const ErrorTranslationMap: Record<LoginError, string> = {
    [LoginError.INCORRECT_CREDENTIALS]: 'login.errors.passwordIncorrect',
    [LoginError.TEMP_PASSWORD_EXPIRED]: 'login.errors.temporaryPasswordExpired',
    [LoginError.SERVER_ERROR]: 'login.errors.serverError',
    [LoginError.TIMEOUT]: 'login.errors.timeoutError',
    [LoginError.UNAUTHORIZED]: 'login.errors.unauthorized',
    [LoginError.RATE_LIMIT]: 'login.errors.rateLimit',
};

interface LoginFormProps {
    apiBaseUrl: string;
    onLoginSuccess: () => void;
    challengeUrl: string;
    forgotPasswordUrl: string;
    tenant: Tenant;
}
export const LoginForm = ({ apiBaseUrl, onLoginSuccess, challengeUrl, forgotPasswordUrl, tenant }: LoginFormProps) => {
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation('uiDomain');
    const [isLoading, setIsLoading] = useState(false);
    const usernameRef = useRef('');
    const { defaultUsername = '', defaultErrorNotification = null } = location.state || {};
    let { defaultSuccessNotification = '' } = location.state || {};
    if (!defaultSuccessNotification && searchParams.get('password_change') === 'true') {
        defaultSuccessNotification = t('login.passwordChangedNotification');
    }
    const [successNotification, setSuccessNotification] = useState(defaultSuccessNotification);
    const [error, setError] = useState<LoginError | null>(defaultErrorNotification);

    const schema = z.object({
        username: z.string().min(1, t('login.errors.usernameRequired')).email(),
        password: z
            .string()
            .min(1, t('login.errors.passwordRequired'))
            .regex(/^\S+$/, t('login.errors.passwordFormatError')),
    });

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<z.infer<typeof schema>>({
        mode: 'onBlur',
        defaultValues: {
            username: defaultUsername,
            password: '',
        },
        resolver: zodResolver(schema),
    });
    const onSubmit = handleSubmit(async ({ username, password }) => {
        const usernameTrim = username.trim();
        usernameRef.current = usernameTrim;
        setSuccessNotification('');
        setError(null);
        try {
            setIsLoading(true);
            const responseBody = await login(apiBaseUrl, usernameTrim, password, tenant);
            if (isLoginSuccess(responseBody)) {
                auth.authenticate(responseBody.accessToken, responseBody.refreshToken);
                onLoginSuccess();
                setIsLoading(false);
            } else {
                navigate(challengeUrl, {
                    state: {
                        session: responseBody.session,
                        username,
                        type: responseBody.challengeType,
                    },
                });
            }
        } catch (e: unknown) {
            setIsLoading(false);
            if (isRateLimitError(e)) {
                setError(LoginError.RATE_LIMIT);
            } else if (isAccessDeniedError(e)) {
                if (e.message.includes('Temporary password has expired')) {
                    setError(LoginError.TEMP_PASSWORD_EXPIRED);
                } else if (e.message.includes('UNAUTHORIZED')) {
                    setError(LoginError.UNAUTHORIZED);
                } else {
                    setError(LoginError.INCORRECT_CREDENTIALS);
                }
            } else {
                setError(LoginError.INCORRECT_CREDENTIALS);
            }
        }
    });

    const onResendEmail = () => {
        setError(null);
        resendWelcomeEmail(apiBaseUrl, usernameRef.current);
        setSuccessNotification(
            t('login.tempPasswordEmailSent', {
                username: usernameRef.current,
            })
        );
    };

    return (
        <main className="login-form">
            <header className="login-form__header">{t('login.headerTitle')}</header>
            <form onSubmit={onSubmit}>
                <FormItem>
                    <Controller
                        name="username"
                        control={control}
                        render={({ field: { onChange, onBlur } }) => (
                            <InputTextfield
                                label={t('login.usernameLabel')}
                                type="text"
                                error={errors.username?.message}
                                onChange={onChange}
                                onBlur={onBlur}
                                endAdornment={<Icon name={IconIdentifier.PERSON} />}
                                defaultValue={defaultUsername}
                                autoFocus={!defaultUsername}
                                placeholder={t('login.usernamePlaceholder')}
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, onBlur } }) => (
                            <InputPassword
                                error={errors.password?.message}
                                onChange={onChange}
                                onBlur={onBlur}
                                autoFocus={!!defaultUsername}
                                placeholder={t('login.passwordPlaceholder')}
                            />
                        )}
                    />
                </FormItem>
                <div className="login-form__forgot-password">
                    <CustomLink isInlineLink path={forgotPasswordUrl}>
                        {t('login.forgotPassword')}
                    </CustomLink>
                </div>
                {error && (
                    <Notification
                        stayOpen
                        level={NotificationLevel.WARNING}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        message={t(ErrorTranslationMap[error])}
                        link={
                            error === LoginError.TEMP_PASSWORD_EXPIRED
                                ? {
                                      onClick: onResendEmail,
                                      label: t('login.resetPassword.resendEmail'),
                                  }
                                : undefined
                        }
                    />
                )}
                {successNotification && (
                    <Notification
                        stayOpen
                        level={NotificationLevel.SUCCESS}
                        message={successNotification}
                        className="login-form__success-notification"
                    />
                )}
                <FormActionbar>
                    <ButtonCallToAction
                        label={t('login.submitLabel')}
                        disabled={isLoading || !isValid}
                        type="submit"
                        loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                    />
                </FormActionbar>
            </form>
        </main>
    );
};
