import { CurrencyCode } from '@steelbuy/currency';
import { ModelConverter, FieldConverterMap } from '@steelbuy/domain-model-converter';
import {
    ArrayOfMonetaryAmountFieldNames,
    ArrayOfTimestampFieldNames,
    ModelPrimaryKey,
    MonetaryAmount,
    MonetaryAmountFieldNames,
    Timestamp,
    TimestampFieldNames,
} from '@steelbuy/domain-model-types';
import { CartEntryModel, CartyEntryModelConverter } from './CartEntryModel';
import { MaterialType, TradeUnit } from './MaterialModel.enums';

export interface WeightAndType {
    type: MaterialType;
    weight: number;
    tradeUnit: TradeUnit;
}

export type CartBuyerModel = {
    readonly id: ModelPrimaryKey;
    readonly buyerId: string;
    readonly cartReferenceNumber: string;
    expiresAt: Timestamp;
    status: string;
    totalValue: MonetaryAmount;
    totalValueVAT: MonetaryAmount;
    typeWithWeight: WeightAndType[];
    credit: number;
    canCheckout: boolean;
    createdAt: Timestamp;
    cartEntries: CartEntryModel[];
    currencyCode: CurrencyCode;
};

export class CartBuyerModelConverter extends ModelConverter<CartBuyerModel> {
    override getTimestampFields(): Array<
        TimestampFieldNames<CartBuyerModel> | ArrayOfTimestampFieldNames<CartBuyerModel>
    > {
        return ['expiresAt', 'createdAt'];
    }

    override getMonetaryAmountFields(): Array<
        MonetaryAmountFieldNames<CartBuyerModel> | ArrayOfMonetaryAmountFieldNames<CartBuyerModel>
    > {
        return ['totalValue', 'totalValueVAT'];
    }

    override getFieldConverterMapByModel(): FieldConverterMap<CartBuyerModel> {
        return {
            cartEntries: new CartyEntryModelConverter(),
        };
    }
}
