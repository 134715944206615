import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CartProductType, StartNegotiationResponse, useAddItemToCart } from '@steelbuy/data-access';
import { getAvailableCredit, useFeatureFlag, useListingBuyerEntityData, useUserSelf } from '@steelbuy/data-provider';
import {
    CartBuyerModel,
    Feature,
    getMinimumTargetPrice,
    getPricingUnit,
    getTradeUnitCalculation,
    ListingStatus,
    OfferListingStatus,
    OfferView,
    TradeUnitCalculation,
    UserRole,
} from '@steelbuy/domain-model';
import { createDateFromTimestamp } from '@steelbuy/domain-model-types';
import { NotFoundError } from '@steelbuy/error';
import { useModal } from '@steelbuy/modal-dialog';

import {
    ListingBuyerDetailsPrice,
    ListingDetailsExpiration,
    MarketingBannerSteelbuyPromise,
    MakeAnOfferModal,
} from '@steelbuy/ui-domain';
import {
    ButtonCallToAction,
    ButtonSecondary,
    ButtonTertiaryOnLightM,
    Card,
    CardContentAttributes,
    CardContentAttributesItem,
    CardContentMaterialLocation,
    CardLayout,
    LoadingStatus,
    Notification,
    NotificationLevel,
    toaster,
    useSuccessErrorNotification,
} from '@steelbuy/ui-primitive';

import { OfferInProgressModal } from './OfferInProgressModal';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { AddToCartConfirmModal } from '../add-to-cart-confirm-modal/AddToCartConfirmModal';
import { useCheckoutContext } from '../checkout/CheckoutContext';
import { CommonListingDetails } from '../common-listing-details/CommonListingDetails';
import { MaterialPageHeader } from '../material-page-header/MaterialPageHeader';
import { Negotiations, hasActiveOffer } from '../offers/Negotiations';

import './ListingDetails.scss';

interface ListingDetailsProps {
    checkoutPath: string;
    goBackPath: string;
    offerSuccessPath: string;
}

export const ListingDetails = ({ checkoutPath, goBackPath, offerSuccessPath }: ListingDetailsProps) => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);

    const {
        Notification: UpdateNotification,
        showSuccessNotification,
        showErrorNotification,
    } = useSuccessErrorNotification();

    const openOfferModal = useModal();
    const openAddToCartModal = useModal();
    const offerInProgressModal = useModal();
    const { isFeatureSupported } = useFeatureFlag();
    const location = useLocation();
    const navigate = useNavigate();
    const userData = useUserSelf().get();
    const checkoutContext = useCheckoutContext();
    const listingEntityData = useListingBuyerEntityData();
    const listingModel = listingEntityData
        .query()
        .getOrThrow(new NotFoundError(t('translation:application.listingNotFound.notFoundMessage')));

    const hasBuyerRole = userData?.roles.includes(UserRole.BUYER) ?? false;
    const addItemToCartMutation = useAddItemToCart();
    const [cartData, setCartData] = useState<CartBuyerModel>();

    const negotiationExists = listingModel?.negotiationExists;

    const doCheckout = () => {
        checkoutContext.initializeWithListingId(listingModel.id);
        navigate(checkoutPath);
    };

    const handleCheckoutButtonClick = () => {
        const isActiveOffer = listingModel?.negotiations?.some(
            (negotiation) =>
                negotiation.status === OfferListingStatus.AWAITING_BUYER ||
                negotiation.status === OfferListingStatus.AWAITING_SELLER
        );
        if (isActiveOffer || negotiationExists) {
            offerInProgressModal.show();
        } else {
            doCheckout();
        }
    };

    const handleAddToCart = (id: string): void => {
        addItemToCartMutation.mutate(
            { productId: id, productType: CartProductType.LISTING },
            {
                onSuccess: (response) => {
                    setCartData(response);
                    openAddToCartModal.show();
                },
                onError: () => toaster.warn(t('application.addToCartConfirmModal.addToCartFailed')),
            }
        );
    };

    const refetchListingDetails = (
        notificationMessage?: string,
        errorNotificationMessage?: string,
        forceRefetch?: boolean
    ) => {
        if (notificationMessage) {
            showSuccessNotification(notificationMessage);
        } else if (errorNotificationMessage) {
            showErrorNotification(errorNotificationMessage);
        }
        if (!errorNotificationMessage || forceRefetch) {
            listingEntityData.resolveFetchStatus();
        }
    };

    const pricingUnit = getPricingUnit(listingModel.material);
    const weightOrItems =
        getTradeUnitCalculation(listingModel.material) === TradeUnitCalculation.BY_WEIGHT
            ? listingModel.weight
            : listingModel.numberOfItems || 0;

    const isBidOfferEnabled =
        isFeatureSupported(Feature.BID_OFFER) &&
        !negotiationExists &&
        !listingModel?.negotiations?.some((negotiation) => negotiation.status === OfferListingStatus.ACCEPTED);

    const isCartSupported = isFeatureSupported(Feature.CART) && hasBuyerRole;

    const taxPercent = listingModel.pricing.taxPercent / 100;

    const renderActionButtons = () => {
        if (!hasBuyerRole) {
            return null;
        }
        return (
            <div
                className={classNames('listing-details__action-bar', {
                    'listing-details__action-bar-with-offer': isBidOfferEnabled,
                })}
            >
                {isBidOfferEnabled && (
                    <ButtonTertiaryOnLightM
                        label={t('translation:application.listingDetails.offerButtonText')}
                        onClick={openOfferModal.show}
                        disabled={hasActiveOffer(listingModel?.negotiations)}
                    />
                )}
                {isCartSupported && (
                    <ButtonSecondary
                        label={t(`uiDomain:commonList.addToCart`)}
                        onClick={() => handleAddToCart(listingModel.id)}
                        loadingStatus={addItemToCartMutation.isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                    />
                )}
                <ButtonCallToAction
                    label={t('translation:application.listingDetails.checkoutButtonText')}
                    onClick={handleCheckoutButtonClick}
                    disabled={!listingModel.canCheckout}
                />
            </div>
        );
    };

    return (
        <>
            <MaterialPageHeader
                material={listingModel.material}
                previousPageTitle={t('uiDomain:common.back')}
                onBackClick={() => navigate(location.state?.goBackPath ?? goBackPath)}
            />

            <div className="listing-details">
                <DetailsLayout>
                    {negotiationExists && (
                        <Notification
                            level={NotificationLevel.INFO}
                            message={t('translation:application.checkout.negotiationExists')}
                        />
                    )}

                    {listingModel.listingStatusType !== ListingStatus.PURCHASED && renderActionButtons()}
                    {hasBuyerRole && !listingModel.canCheckout && (
                        <Notification
                            level={NotificationLevel.ERROR}
                            message={t('translation:application.checkout.creditLimitExceeded')}
                        />
                    )}

                    {UpdateNotification}
                    <Negotiations
                        negotiations={listingModel.negotiations}
                        view={OfferView.BUYER}
                        onStatusChange={refetchListingDetails}
                        pricingUnit={pricingUnit}
                        weightOrItems={weightOrItems}
                        taxPercent={taxPercent}
                        numberOffers={listingModel.totalNumberOfNegotiation}
                        numberActiveOffers={listingModel.numberOfActiveNegotiation}
                        minimumPrice={getMinimumTargetPrice(
                            listingModel.material,
                            listingModel.weight,
                            listingModel.numberOfItems
                        )}
                    />
                    <CommonListingDetails listingModel={listingModel} />
                    <ListingBuyerDetailsPrice listing={listingModel} />
                    {/* Delivery CARD */}
                    <Card isClickable={false}>
                        <CardLayout>
                            <CardContentAttributes header={t('translation:application.listingDetails.deliveryTitle')}>
                                <CardContentAttributesItem
                                    label={t('translation:application.listingDetails.deliveryText')}
                                    value={t(`domainModel:deliveryPromise.${listingModel.deliveryPromise}`)}
                                />
                                {listingModel.country && (
                                    <CardContentMaterialLocation countryCode={listingModel.country} />
                                )}
                            </CardContentAttributes>
                        </CardLayout>
                    </Card>
                    <ListingDetailsExpiration expiryDate={createDateFromTimestamp(listingModel.expiresAt)} />
                    <MarketingBannerSteelbuyPromise />
                    {listingModel.listingStatusType !== ListingStatus.PURCHASED && renderActionButtons()}
                    <MakeAnOfferModal
                        modalAccessor={openOfferModal}
                        currencyCode={listingModel.pricing.currencyCode}
                        creditLimit={getAvailableCredit(userData)}
                        originalPrice={listingModel.pricing.priceIncludingDelivery.value}
                        pricingUnit={pricingUnit}
                        weightOrItems={weightOrItems}
                        listingId={listingModel.id}
                        onSuccess={({ referenceId }: StartNegotiationResponse) => {
                            listingEntityData.resolveFetchStatus();
                            navigate(offerSuccessPath, {
                                state: {
                                    referenceId,
                                },
                            });
                        }}
                        taxPercent={taxPercent}
                        minimumPrice={getMinimumTargetPrice(
                            listingModel.material,
                            listingModel.weight,
                            listingModel.numberOfItems
                        )}
                    />
                    {cartData && <AddToCartConfirmModal modalAccessor={openAddToCartModal} cartData={cartData} />}

                    <OfferInProgressModal offerInProgressModal={offerInProgressModal} handleBuyNow={doCheckout} />
                </DetailsLayout>
            </div>
        </>
    );
};
