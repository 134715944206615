import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AnalyticsProvider } from '@steelbuy/analytics';
import { logout, Tenant } from '@steelbuy/api-integration';
import { Authenticated, AuthProvider, RefreshToken, Unauthenticated } from '@steelbuy/auth';

import { UserSelfEntityDataProvider } from '@steelbuy/data-provider';
import { ErrorHandler } from '@steelbuy/error';
import { ToasterContainer } from '@steelbuy/ui-primitive';
import { AppMainLayout } from './components/app-layout/app-main-layout/AppMainLayout';
import { AppLayout } from './components/app-layout/AppLayout';
import { LocationAwareGenericError } from './components/generic-error/LocationAwareGenericError';
import { Navigation } from './components/navigation/Navigation';
import { constants } from './constants';
import { LoginRouter } from './router/LoginRouter';
import { Router } from './router/Router';
import { UnauthenticatedRouter } from './router/UnauthenticatedRouter';
import { UNAUTHENTICATE_SIGNAL } from '../store/store';

import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = t('application.pageTitle');
    }, [navigator.language]);

    const handleReauthenticate = () => {
        dispatch({ type: UNAUTHENTICATE_SIGNAL });
    };

    const handleUnauthenticate = async (refreshToken?: RefreshToken, queryParam?: string) => {
        dispatch({ type: UNAUTHENTICATE_SIGNAL });
        refreshToken && (await logout(constants.apiBaseUrl, refreshToken, Tenant.MARKETPLACE));
        // use window.location so we reset state
        let location = 'login';
        if (queryParam) {
            location += `?${queryParam}`;
        }
        window.location.assign(location);
    };

    const handleError = (error: Error, resetError: () => void) => (
        <LocationAwareGenericError error={error} resetError={resetError} />
    );

    return (
        <AuthProvider onReauthenticate={handleReauthenticate} onUnauthenticate={handleUnauthenticate}>
            <LoginRouter>
                <Authenticated>
                    <UserSelfEntityDataProvider>
                        <AnalyticsProvider>
                            <AppLayout>
                                <Navigation />
                                <AppMainLayout>
                                    <ErrorHandler errorComponent={handleError}>
                                        <Router />
                                    </ErrorHandler>
                                </AppMainLayout>
                            </AppLayout>
                        </AnalyticsProvider>
                    </UserSelfEntityDataProvider>
                </Authenticated>
                <Unauthenticated>
                    <AppLayout>
                        <>
                            <Navigation isAuthenticated={false} />
                            <AppMainLayout>
                                <ErrorHandler errorComponent={handleError}>
                                    <UnauthenticatedRouter />
                                </ErrorHandler>
                            </AppMainLayout>
                        </>
                    </AppLayout>
                </Unauthenticated>
            </LoginRouter>
            <ToasterContainer />
        </AuthProvider>
    );
};
