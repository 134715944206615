import { ReactNode } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { buildClassStringFromClassMap } from '@steelbuy/util';

import './CustomLink.scss';

export type CustomLinkProps = {
    children?: ReactNode;
    path: string;
    isInlineLink?: boolean;
    isAnchorLink?: boolean;
    onDark?: boolean;
    openInNewTab?: boolean;
} & Omit<LinkProps, 'to'>;

export const CustomLink = ({
    children,
    isAnchorLink = false,
    isInlineLink = false,
    onDark = false,
    openInNewTab = false,
    path,
    ...rest
}: CustomLinkProps) => {
    const location = useLocation();
    const match = location.pathname === path;

    const customLinkClassmap = {
        'custom-link': true,
        'custom-link--active': match,
        'custom-link--inline': isInlineLink,
        'custom-link--on-dark': onDark,
    };

    const openInNewTabObj = {
        target: '_blank',
        rel: 'noopener noreferrer',
    };

    if (isAnchorLink) {
        return (
            <a className={buildClassStringFromClassMap(customLinkClassmap)} href={path} {...rest}>
                {children}
            </a>
        );
    }
    return (
        <Link
            className={buildClassStringFromClassMap(customLinkClassmap)}
            to={path}
            {...(openInNewTab ? openInNewTabObj : {})}
            {...rest}
        >
            {children}
        </Link>
    );
};
