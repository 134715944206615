import { useTranslation } from 'react-i18next';
import { useCartDetails } from '@steelbuy/data-access';
import { ListingBuyerTeaser, MyCartWeightType } from '@steelbuy/ui-domain';
import { Card, LoadingSpinner } from '@steelbuy/ui-primitive';
import { MyCartEmptyState } from './MyCartEmptyState';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { ActFastNotification } from '../act-fast-notification/ActFastNotification';
import { PageHeader } from '../page-header/PageHeader';
import './MyCart.scss';

export const MyCart = () => {
    const { t } = useTranslation('translation');
    const { data, isLoading } = useCartDetails();

    return (
        <div className="my-cart-list">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PageHeader pageTitle={t('application.myCart.header')} />
                    <TableLayout>
                        <ActFastNotification expiresAt={data?.cartEntries?.length ? '00:00' : undefined} />
                        {data?.cartEntries?.length ? (
                            <div className="my-cart-list__container">
                                <div className="my-cart-list__container__listing-teaser">
                                    {data.cartEntries.map((entry) => {
                                        const isPackage = entry.productType === 'PACKAGE';
                                        return (
                                            <Card
                                                key={entry.id}
                                                isClickable
                                                url={createRouteUrl(
                                                    isPackage
                                                        ? RoutePath.PACKAGE_SEARCH_DETAILS
                                                        : RoutePath.SEARCH_LISTING_DETAILS,
                                                    [isPackage ? 'packageId' : 'listingId', entry.product.id]
                                                )}
                                                linkState={{
                                                    goBackPath: RoutePath.MY_CART,
                                                }}
                                            >
                                                {isPackage ? (
                                                    <ListingBuyerTeaser listing={entry.product} isCart isPackage />
                                                ) : (
                                                    <ListingBuyerTeaser listing={entry.product} isCart />
                                                )}
                                            </Card>
                                        );
                                    })}
                                </div>
                                <MyCartWeightType
                                    typeWithWeight={data.typeWithWeight}
                                    noOfEntries={data.cartEntries.length}
                                    totalValue={data.totalValue.value}
                                    currencyCode={data.currencyCode}
                                />
                            </div>
                        ) : (
                            <div className="my-cart-list__empty-state-container">
                                <MyCartEmptyState />
                            </div>
                        )}
                    </TableLayout>
                </>
            )}
        </div>
    );
};
