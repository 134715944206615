import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { ModelPrimaryKey } from '@steelbuy/domain-model-types';
import { CustomError } from '@steelbuy/error';

export interface CheckoutContextValue {
    listingId?: ModelPrimaryKey;
    initializeWithListingId: (listingId: ModelPrimaryKey) => void;
    deliveryAddressId?: ModelPrimaryKey;
    setDeliveryAddressId: (deliveryAddressId?: ModelPrimaryKey) => void;
    additionalDeliveryInformation?: string;
    setAdditionalDeliveryInformation: (additionalDeliveryInformation: string) => void;
    haulierRequired: boolean;
    setHaulierRequired: (haulierRequired: boolean) => void;
    buyerOrderNumber?: string;
    setBuyerOrderNumber: (buyerOrderNumber: string) => void;
    agreedTermsAndConditions: boolean;
    setAgreedTermsAndConditions: (agreed: boolean) => void;
    agreedAuthorised: boolean;
    setAgreedAuthorised: (agreed: boolean) => void;
    reset: () => void;
}

const CheckoutContext = createContext<CheckoutContextValue | undefined>(undefined);

export const CheckoutContextProvider = (props: {
    // eslint-disable-next-line react/no-unused-prop-types
    listingId?: ModelPrimaryKey;
    children: React.ReactNode;
}) => {
    const { children } = props;

    const [listingId, setListingId] = useState<ModelPrimaryKey>();
    const [deliveryAddressId, setDeliveryAddressId] = useState<ModelPrimaryKey>();
    const [additionalDeliveryInformation, setAdditionalDeliveryInformation] = useState<string>();
    const [buyerOrderNumber, setBuyerOrderNumber] = useState<string>();
    const [agreedTermsAndConditions, setAgreedTermsAndConditions] = useState<boolean>(false);
    const [agreedAuthorised, setAgreedAuthorised] = useState<boolean>(false);
    const [haulierRequired, setHaulierRequired] = useState<boolean>(false);
    const initializeWithListingId = (newListingId: ModelPrimaryKey) => {
        if (newListingId !== listingId) {
            setListingId(newListingId);
            setDeliveryAddressId(undefined);
            setAdditionalDeliveryInformation(undefined);
            setBuyerOrderNumber(undefined);
            setAgreedTermsAndConditions(false);
            setHaulierRequired(false);
            setAgreedAuthorised(false);
        }
    };

    const reset = useCallback(() => {
        setListingId(undefined);
        setDeliveryAddressId(undefined);
        setAdditionalDeliveryInformation(undefined);
        setBuyerOrderNumber(undefined);
        setAgreedTermsAndConditions(false);
        setHaulierRequired(false);
        setAgreedAuthorised(false);
    }, []);

    const value = useMemo(
        () => ({
            listingId,
            initializeWithListingId,
            deliveryAddressId,
            setDeliveryAddressId,
            additionalDeliveryInformation,
            setAdditionalDeliveryInformation,
            buyerOrderNumber,
            setBuyerOrderNumber,
            agreedTermsAndConditions,
            setAgreedTermsAndConditions,
            agreedAuthorised,
            setAgreedAuthorised,
            haulierRequired,
            setHaulierRequired,
            reset,
        }),
        [
            listingId,
            deliveryAddressId,
            additionalDeliveryInformation,
            buyerOrderNumber,
            agreedTermsAndConditions,
            agreedAuthorised,
            haulierRequired,
            reset,
        ]
    );

    return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>;
};

export const useCheckoutContext = () => {
    const context = useContext(CheckoutContext);

    if (!context) {
        throw new CustomError('No provider present for CheckoutContext!');
    }

    return context;
};
