import { useNavigate } from 'react-router-dom';
import { useIsFeatureSupported, userHasRole, useUserSelf } from '@steelbuy/data-provider';
import { Feature, UserRole } from '@steelbuy/domain-model';
import { MyCart } from '../../components/my-cart/MyCart';
import { RoutePath } from '../../router/Routes';

export const MyCartView = () => {
    const navigate = useNavigate();
    const userModel = useUserSelf().get();
    const hasBuyerRole = userHasRole(userModel, [UserRole.BUYER]);
    const showCart = useIsFeatureSupported(Feature.CART) && hasBuyerRole;

    if (!showCart) {
        navigate(RoutePath.DASHBOARD);
    }

    return <MyCart />;
};
