import {
    AccessDeniedErrorObject,
    ApiErrorObject,
    AuthenticationFailedErrorObject,
    AuthenticationRequiredErrorObject,
    ErrorObject,
    NetworkErrorObject,
    NotFoundErrorObject,
    RateLimitErrorObject,
    StoreErrorObject,
    TimeoutErrorObject,
    ValidationErrorObject,
    WebSocketErrorObject,
} from './ErrorObject';

export const isErrorObject = (errorObject: unknown): errorObject is ErrorObject =>
    (errorObject as ErrorObject)?.discriminator === 'CustomError';

export const isStoreError = (error: unknown): error is StoreErrorObject =>
    (error as StoreErrorObject)?.discriminator === 'StoreError';

export const isNotFoundError = (error: unknown): error is NotFoundErrorObject =>
    (error as NotFoundErrorObject)?.discriminator === 'NotFoundError';

export const isTimeoutError = (error: unknown): error is TimeoutErrorObject =>
    (error as TimeoutErrorObject)?.discriminator === 'TimeoutError';

export const isNetworkError = (error: unknown): error is NetworkErrorObject =>
    (error as NetworkErrorObject)?.discriminator === 'NetworkError';

export const isApiError = (error: unknown): error is ApiErrorObject =>
    (error as ApiErrorObject)?.discriminator === 'ApiError';

export const isValidationError = (error: unknown): error is ValidationErrorObject =>
    (error as ValidationErrorObject)?.discriminator === 'ValidationError';

export const isAuthenticationRequiredError = (error: unknown): error is AuthenticationRequiredErrorObject =>
    (error as AuthenticationRequiredErrorObject)?.discriminator === 'AuthenticationRequiredError';

export const isAuthenticationFailedError = (error: unknown): error is AuthenticationFailedErrorObject =>
    (error as AuthenticationFailedErrorObject)?.discriminator === 'AuthenticationFailedError';

export const isAccessDeniedError = (error: unknown): error is AccessDeniedErrorObject =>
    (error as AccessDeniedErrorObject)?.name === 'AccessDeniedError';

export const isRateLimitError = (error: unknown): error is RateLimitErrorObject =>
    (error as RateLimitErrorObject)?.name === 'RateLimitError';

export const isWebSocketError = (error: unknown): error is WebSocketErrorObject =>
    (error as WebSocketErrorObject)?.discriminator === 'WebSocketError';
