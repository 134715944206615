import { useEffect } from 'react';
import { toast, ToastContainer, Id, Zoom } from 'react-toastify';
import { useWindowSize } from '@steelbuy/util';
import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';

import './Toast.scss';

const TOAST_MARGIN = 40;

const getToastWidth = (mainLayoutEl: HTMLElement | null) => {
    if (!mainLayoutEl) {
        return 360;
    }
    return mainLayoutEl.clientWidth - TOAST_MARGIN * 2 + 1;
};

const getToastLeft = (mainLayoutEl: HTMLElement | null) => {
    if (!mainLayoutEl) {
        return 0;
    }
    return mainLayoutEl.offsetLeft - 1 + TOAST_MARGIN;
};

export const ToasterContainer = () => {
    const { width } = useWindowSize();

    useEffect(() => {
        let mainLayoutEl = document.getElementById('app-main-layout');

        const setToastWidth = () => {
            const root: HTMLElement | null = document.querySelector(':root');
            root?.style.setProperty('--toastify-toast-width', `${getToastWidth(mainLayoutEl)}px`);
            root?.style.setProperty('--toastify-toast-left', `${getToastLeft(mainLayoutEl)}px`);
        };

        const waitForMainLayout = () => {
            setTimeout(() => {
                mainLayoutEl = document.getElementById('app-main-layout');
                if (!mainLayoutEl) {
                    waitForMainLayout();
                } else {
                    setToastWidth();
                }
            }, 1000);
        };

        if (!mainLayoutEl) {
            waitForMainLayout();
        } else {
            setToastWidth();
        }
    }, [width]);
    return (
        <ToastContainer
            stacked
            position="bottom-left"
            autoClose={10000}
            transition={Zoom}
            hideProgressBar
            closeOnClick
            closeButton
        />
    );
};

const TOAST_METHODS = ['success', 'error', 'warn', 'info'] as const;

type Toast = Record<typeof TOAST_METHODS[number], (message: string) => Id>;

// if we need more control we can use a component
// const ToastComponent = ({ message, type }: { message: string; type: typeof TOAST_METHODS[number] }) => (
//     <div
//         className={classNames('toast', {
//             '--success': type === 'success',
//             '--error': type === 'error',
//             '--warn': type === 'warn',
//             '--info': type === 'info',
//         })}
//     >
//         {message}
//     </div>
// );
//
// export const toaster = TOAST_METHODS.reduce((acc, method) => {
//     acc[method] = (message: string) => toast[method](<ToastComponent message={message} type={method} />, {
//     className: `toast-wrapper toast-wrapper--${method}`,
//     icon: <Icon name={getIconName(method)} />,
// });
//     return acc;
// }, {} as Toast);

const getIconName = (method: typeof TOAST_METHODS[number]) => {
    switch (method) {
        case 'info':
            return IconIdentifier.INFO;

        case 'success':
            return IconIdentifier.WORKFLOW_SUCCESS;

        case 'warn':
            return IconIdentifier.ERROR;

        case 'error':
            return IconIdentifier.CANCEL;

        default:
            return IconIdentifier.INFO;
    }
};

const setToastPosition = () => {
    const mainLayoutEl = document.getElementById('app-main-layout');
    if (!mainLayoutEl) {
        return;
    }
    const root: HTMLElement | null = document.querySelector(':root') as HTMLElement;
    root?.style.setProperty('--toastify-toast-width', `${getToastWidth(mainLayoutEl)}px`);
    root?.style.setProperty('--toastify-toast-left', `${getToastLeft(mainLayoutEl)}px`);
};

export const toaster = TOAST_METHODS.reduce((acc, method) => {
    acc[method] = (message: string) => {
        setToastPosition();
        return toast[method](message, {
            className: `toast-wrapper toast-wrapper--${method}`,
            icon: <Icon name={getIconName(method)} />,
        });
    };
    return acc;
}, {} as Toast);
