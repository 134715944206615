import { useQuery } from '@tanstack/react-query';
import { CartBuyerModel, CartBuyerModelConverter } from '@steelbuy/domain-model';
import { ApiModel } from '@steelbuy/domain-model-types';
import axios from '../../axios';

const modelConverter = new CartBuyerModelConverter();

const getCartDetails = async () => {
    const { data } = await axios.get<CartBuyerModel>(`/api/buyer/cart/fetch-cart`);
    const newData = modelConverter.toViewModel(data as unknown as ApiModel<CartBuyerModel>);
    return newData;
};

export const useCartDetails = () =>
    useQuery({
        queryKey: ['cart-details'],
        queryFn: () => getCartDetails(),
        cacheTime: 0,
    });
