import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomLink } from '@steelbuy/ui-primitive';
import { ForgotPasswordForm } from '../../forgot-password/ForgotPasswordForm';
import { PasswordLinkSent } from '../../forgot-password/PasswordLinkSent';

import { LoginLayout } from '../login-layout/LoginLayout';
import './ForgotPassword.scss';

interface ForgotPasswordProps {
    loginRoute: string;
    forgotPasswordRoute: string;
    apiBaseUrl: string;
}

export const ForgotPassword = ({ apiBaseUrl, loginRoute, forgotPasswordRoute }: ForgotPasswordProps) => {
    const { t } = useTranslation('uiDomain');
    const [username, setUsername] = useState('');

    const onSuccess = (user: string) => {
        setUsername(user);
    };

    return (
        <LoginLayout>
            {username ? (
                <>
                    <PasswordLinkSent loginUrl={loginRoute} username={username} />
                    <div className="forgot-password__link" onClick={() => setUsername('')} aria-hidden="true">
                        <CustomLink path={forgotPasswordRoute} isInlineLink>
                            {t('login.resetPassword.resendEmail')}
                        </CustomLink>
                    </div>
                </>
            ) : (
                <>
                    <ForgotPasswordForm apiBaseUrl={apiBaseUrl} onSuccess={onSuccess} defaultUsername={username} />
                    <div className="forgot-password__link">
                        <CustomLink path={loginRoute} isInlineLink>
                            {t('login.backToLogin')}
                        </CustomLink>
                    </div>
                </>
            )}
        </LoginLayout>
    );
};
