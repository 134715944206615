import { useTranslation } from 'react-i18next';
import { AddressEntityDataProvider, useAddressEntityData } from '@steelbuy/data-provider';
import { ErrorHandler, NotFoundError } from '@steelbuy/error';
import { Address, AddressLoadingIndicator } from '@steelbuy/ui-domain';
import { AddressIdentifier, CardContentAddressDetails, ErrorHandlerNotitification } from '@steelbuy/ui-primitive';

const CurrentAddress = () => {
    const addressEntityData = useAddressEntityData();

    const addressModel = addressEntityData.query().getOrThrow(new NotFoundError());

    return <Address addressModel={addressModel} />;
};

interface AddressDetailsProps {
    addressId?: string;
    type: AddressIdentifier;
    additionalText?: string;
    onChangeAdditionalText?: (value: string) => void;
    onButtonClick?: () => void;
    readonly?: boolean;
    additionalInfoLabel: string;
    additionalInfoPlaceholder?: string;
    attributeLabel?: string;
}

export const AddressDetails = ({
    additionalText,
    addressId,
    onButtonClick,
    onChangeAdditionalText,
    additionalInfoLabel,
    additionalInfoPlaceholder,
    readonly,
    type,
    attributeLabel,
}: AddressDetailsProps) => {
    const { t } = useTranslation('translation');

    if (!addressId) {
        return null;
    }

    return (
        <CardContentAddressDetails
            heading={t(`application.addressDetails.sectionHeading.${type}`)}
            type={type}
            attributeLabel={attributeLabel}
            additionalPlaceholder={additionalInfoPlaceholder}
            additionalText={additionalText}
            additionalTextLabel={additionalInfoLabel}
            onChangeAdditionalText={onChangeAdditionalText}
            chooseOtherAddressButtonLabel={t('application.addressDetails.chooseAddressButtonLabel')}
            onChooseOtherAddressButtonClick={onButtonClick}
            readonly={readonly}
        >
            <ErrorHandler
                errorComponent={ErrorHandlerNotitification(t('application.addressDetails.addressNotLoadable'))}
            >
                <AddressEntityDataProvider entityId={addressId} pendingComponent={AddressLoadingIndicator}>
                    <CurrentAddress />
                </AddressEntityDataProvider>
            </ErrorHandler>
        </CardContentAddressDetails>
    );
};
