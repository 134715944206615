import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tenant } from '@steelbuy/api-integration';

import { useAuth } from '@steelbuy/auth';
import { Login } from '@steelbuy/ui-domain';
import { constants } from '../constants';
import { RoutePath } from '../router/Routes';

export const LoginView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();
    const [isTokenChecked, setIsTokenChecked] = useState(false);

    useEffect(() => {
        if (auth.isAuthenticated()) {
            navigate(RoutePath.DASHBOARD);
        } else {
            setIsTokenChecked(true);
        }
    }, []);

    const onLoginSuccess = () => {
        navigate(location.state?.path ?? RoutePath.DASHBOARD);
    };

    if (!isTokenChecked) {
        return null;
    }

    return (
        <Login
            apiBaseUrl={constants.apiBaseUrl}
            challengeUrl={RoutePath.CHALLENGE_RESPOND}
            onSuccess={onLoginSuccess}
            forgotPasswordUrl={RoutePath.FORGOT_PASSWORD}
            tenant={Tenant.MARKETPLACE}
        />
    );
};
