import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';
import { PackageSellerDraftModel } from '@steelbuy/domain-model';
import { ResponseModel } from './types';

export const removeItemFromCache = (queryClient: QueryClient, cacheKey: QueryKey, removeId: string) => {
    queryClient.setQueryData(
        cacheKey,
        (cachedDraftPackages: InfiniteData<ResponseModel<PackageSellerDraftModel[]>> | undefined) => {
            if (!cachedDraftPackages) {
                return;
            }
            // eslint-disable-next-line consistent-return
            return {
                ...cachedDraftPackages,
                pages: cachedDraftPackages.pages.map((page) => ({
                    ...page,
                    total: page.total - 1,
                    items: page.items.filter((item) => item.id !== removeId),
                })),
            };
        }
    );
};
