import { useNavigate } from 'react-router-dom';
import { useIsFeatureSupported, userHasRole, useUserSelf } from '@steelbuy/data-provider';
import { Feature, UserRole } from '@steelbuy/domain-model';
import { Icon, IconIdentifier } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';

export const CartIcon = () => {
    const userModel = useUserSelf().get();
    const hasBuyerRole = userHasRole(userModel, [UserRole.BUYER]);
    const showCart = useIsFeatureSupported(Feature.CART) && hasBuyerRole;

    const navigate = useNavigate();

    if (!showCart) {
        return null;
    }
    return <Icon name={IconIdentifier.CART} onClick={() => navigate(RoutePath.MY_CART)} />;
};
