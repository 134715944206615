import { Trans, useTranslation } from 'react-i18next';
import { OrderBuyerModel } from '@steelbuy/domain-model';
import { CustomLink, Icon, IconIdentifier } from '@steelbuy/ui-primitive';

import './CheckoutIllustratedSuccess.scss';

type CheckoutIllustratedSuccessProps = {
    orderModel: Partial<OrderBuyerModel>;
    myOrdersUrl: string;
};

export const CheckoutIllustratedSuccess = (props: CheckoutIllustratedSuccessProps) => {
    const { orderModel, myOrdersUrl } = props;
    const { t } = useTranslation('translation');

    return (
        <section className="checkout-illustration-success">
            <img alt="Success" src="/assets/lib-ui-primitive/images/Checkout-Success.svg" />

            <div className="checkout-illustration-success__header">{t('application.checkoutSuccess.successText')}</div>
            <div className="checkout-illustration-success__help-text">
                {t('application.checkoutSuccess.orderNumberText')}{' '}
                <span className="checkout-illustration-success__order-number">{orderModel.orderNumber}</span>
            </div>

            <section className="checkout-illustration-success__hint">
                <span className="checkout-illustration-success__hint__wrapper">
                    <Icon name={IconIdentifier.OK} />
                    <div className="checkout-illustration-success__hint__wrapper__text">
                        {t('application.checkoutSuccess.emailHint')}
                    </div>
                </span>
                <span className="checkout-illustration-success__hint__wrapper">
                    <Icon name={IconIdentifier.OK} />
                    <div className="checkout-illustration-success__hint__wrapper__text">
                        {t('application.checkoutSuccess.deliveryHint')}
                    </div>
                </span>
                <span className="checkout-illustration-success__hint__wrapper">
                    <Icon name={IconIdentifier.OK} />
                    <div className="checkout-illustration-success__hint__wrapper__text">
                        <Trans
                            i18nKey="application.checkoutSuccess.manageOrder"
                            components={{ link1: <CustomLink path={myOrdersUrl} isInlineLink /> }}
                            t={t}
                        />
                    </div>
                </span>
            </section>
        </section>
    );
};
