import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CartProductType, useAddItemToCart, usePackageDetails } from '@steelbuy/data-access';
import { useFeatureFlag, useUserSelf } from '@steelbuy/data-provider';
import { UserRole, ListingBuyerModel, Feature, CartBuyerModel } from '@steelbuy/domain-model';
import { createDateFromTimestamp } from '@steelbuy/domain-model-types';
import { NotFoundError } from '@steelbuy/error';
import { useModal } from '@steelbuy/modal-dialog';
import {
    ListingDetailsExpiration,
    MarketingBannerSteelbuyPromise,
    ListingBuyerDetailsPrice,
} from '@steelbuy/ui-domain';
import {
    Card,
    CardLayout,
    CardContentAttributes,
    CardContentAttributesItem,
    ButtonCallToAction,
    Notification,
    NotificationLevel,
    LoadingSpinner,
    CardContentMaterialLocation,
    ButtonSecondary,
    LoadingStatus,
    toaster,
} from '@steelbuy/ui-primitive';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { AddToCartConfirmModal } from '../add-to-cart-confirm-modal/AddToCartConfirmModal';
import { useCheckoutContext } from '../checkout/CheckoutContext';
import { CommonPackageDetails } from '../common-listing-details/CommonPackageDetails';
import { MaterialPageHeader } from '../material-page-header/MaterialPageHeader';

import './PackageDetails.scss';

interface PackageDetailsProps {
    checkoutPath: string;
    goBackPath: string;
    packageListingId: string;
}

export const PackageDetails = ({ checkoutPath, goBackPath, packageListingId }: PackageDetailsProps) => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);
    const {
        data: packageModel,
        isLoading,
        error,
    } = usePackageDetails({
        packageId: packageListingId,
        queryOptions: {
            enabled: true,
        },
    });
    const navigate = useNavigate();
    const location = useLocation();
    const userData = useUserSelf().get();
    const checkoutContext = useCheckoutContext();
    const { isFeatureSupported } = useFeatureFlag();

    const hasBuyerRole = userData?.roles.includes(UserRole.BUYER) ?? false;
    const isCartSupported = isFeatureSupported(Feature.CART) && hasBuyerRole;
    const addItemToCartMutation = useAddItemToCart();
    const [cartData, setCartData] = useState<CartBuyerModel>();
    const openAddToCartModal = useModal();

    const handleCheckoutButtonClick = () => {
        if (packageModel) {
            checkoutContext.initializeWithListingId(packageModel.id);
            navigate(checkoutPath);
        }
    };

    const handleAddToCart = (id: string): void => {
        addItemToCartMutation.mutate(
            { productId: id, productType: CartProductType.PACKAGE },
            {
                onSuccess: (response) => {
                    setCartData(response);
                    openAddToCartModal.show();
                },
                onError: () => toaster.warn(t('application.addToCartConfirmModal.addToCartFailed')),
            }
        );
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }
    if (error || !packageModel) {
        throw new NotFoundError();
    }
    const renderActionButtons = () => {
        if (!hasBuyerRole) {
            return null;
        }
        return (
            <div className={classNames('listing-details__action-bar')}>
                {isCartSupported && (
                    <ButtonSecondary
                        label={t(`uiDomain:commonList.addToCart`)}
                        onClick={() => handleAddToCart(packageModel.id)}
                        loadingStatus={addItemToCartMutation.isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                    />
                )}
                <ButtonCallToAction
                    label={t('translation:application.listingDetails.checkoutButtonText')}
                    onClick={handleCheckoutButtonClick}
                    disabled={!packageModel.canCheckout}
                />
            </div>
        );
    };

    return (
        <>
            <MaterialPageHeader
                packageModel={packageModel}
                previousPageTitle={t('uiDomain:common.back')}
                onBackClick={() => navigate(location.state?.goBackPath ?? goBackPath)}
            />
            <div className="package-details">
                <DetailsLayout>
                    {renderActionButtons()}

                    {hasBuyerRole && !packageModel?.canCheckout && (
                        <Notification
                            level={NotificationLevel.ERROR}
                            message={t('translation:application.checkout.creditLimitExceeded')}
                        />
                    )}

                    <CommonPackageDetails packageModel={packageModel} />

                    <ListingBuyerDetailsPrice
                        listing={
                            {
                                ...packageModel,
                                material: { materialType: packageModel.material },
                            } as unknown as ListingBuyerModel
                        }
                    />

                    <Card isClickable={false}>
                        <CardLayout>
                            <CardContentAttributes header={t('translation:application.listingDetails.deliveryTitle')}>
                                <CardContentAttributesItem
                                    label={t('translation:application.listingDetails.deliveryText')}
                                    value={t(`domainModel:deliveryPromise.${packageModel.deliveryTimeframe}`)}
                                />
                                {packageModel.country && (
                                    <CardContentMaterialLocation countryCode={packageModel.country} />
                                )}
                            </CardContentAttributes>
                        </CardLayout>
                    </Card>
                    <ListingDetailsExpiration expiryDate={createDateFromTimestamp(packageModel.expiresAt)} />

                    <MarketingBannerSteelbuyPromise />

                    {renderActionButtons()}
                    {cartData && <AddToCartConfirmModal modalAccessor={openAddToCartModal} cartData={cartData} />}
                </DetailsLayout>
            </div>
        </>
    );
};
