import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { sendPasswordResetEmail } from '@steelbuy/api-integration';
import {
    ButtonCallToAction,
    FormActionbar,
    FormItem,
    Icon,
    IconIdentifier,
    InputTextfield,
} from '@steelbuy/ui-primitive';

import './ForgotPassword.scss';

interface ForgotPasswordFormProps {
    onSuccess: (username: string) => void;
    defaultUsername: string;
    apiBaseUrl: string;
}

export const ForgotPasswordForm = ({ apiBaseUrl, onSuccess, defaultUsername }: ForgotPasswordFormProps) => {
    const { t } = useTranslation('uiDomain');

    const schema = z.object({
        username: z.string().email(t('forgotPassword.enterValidEmail')).min(1, t('forgotPassword.enterValidEmail')),
    });

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<z.infer<typeof schema>>({
        defaultValues: {
            username: defaultUsername,
        },
        resolver: zodResolver(schema),
        mode: 'onBlur',
    });

    const onFormSubmit = handleSubmit(async ({ username }) => {
        const usernameTrim = username.trim();
        sendPasswordResetEmail(apiBaseUrl, usernameTrim);
        onSuccess(usernameTrim);
    });

    return (
        <main className="forgot-password-form">
            <header className="forgot-password-form__header">
                <div>{t('forgotPassword.title')}</div>
                <section className="forgot-password-form__header--subheading">
                    <p>{t('forgotPassword.subheading')}</p>
                </section>
            </header>
            <form onSubmit={onFormSubmit}>
                <FormItem>
                    <Controller
                        name="username"
                        control={control}
                        render={({ field: { onChange, onBlur } }) => (
                            <InputTextfield
                                defaultValue={defaultUsername}
                                label={t('login.usernameLabel')}
                                type="text"
                                error={errors.username?.message}
                                onChange={onChange}
                                onBlur={onBlur}
                                endAdornment={<Icon name={IconIdentifier.PERSON} />}
                                placeholder={t('login.usernamePlaceholder')}
                            />
                        )}
                    />
                </FormItem>
                <FormActionbar>
                    <ButtonCallToAction label={t('forgotPassword.submitLabel')} disabled={!isValid} type="submit" />
                </FormActionbar>
            </form>
        </main>
    );
};
