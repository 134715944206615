import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { TrackingEvent, useAnalytics } from '@steelbuy/analytics';
import { RoutePath } from './Routes';
import { CheckoutPackageFormView } from '../components/checkout/CheckoutPackageFormView';
import { CreateRFQSuccess } from '../components/create-rfq/create-rfq-success/CreateRFQSuccess';
import { CreateRFQSummary } from '../components/create-rfq/create-rfq-summary/CreateRFQSummary';
import { CreateDraftRFQ } from '../components/create-rfq/CreateDraftRFQ';
import { CreateRFQ } from '../components/create-rfq/CreateRFQ';
import { Step1 } from '../components/create-rfq/step1/Step1';
import { Step2 } from '../components/create-rfq/step2/Step2';
import { Step3 } from '../components/create-rfq/step3/Step3';
import { Dashboard } from '../components/dashboard/Dashboard';
import { OfferSuccess } from '../components/listing-details/OfferSuccess';
import { ManageOffers } from '../components/manage-offers/ManageOffers';
import { MyOffers } from '../components/my-offers/MyOffers';
import { RFQDetailsSuccess } from '../components/my-rfqs/RFQDetailsSuccess';
import { UrgentRequestsSettings } from '../components/urgent-requests/UrgentRequestsSettings';
import { AcceptableUseView } from '../views/AcceptableUsePolicyView';
import { AccountAddAddressView } from '../views/account/AccountAddAddressView';
import { AccountEditAddressView } from '../views/account/AccountEditAddressView';
import { AccountEditPaymentTermsView } from '../views/account/AccountEditPaymentTermsView';
import { AccountView } from '../views/account/AccountView';
import { AddUserView } from '../views/account/AddUserView';
import { ChangePasswordView } from '../views/account/ChangePasswordView';
import { EditEmailView } from '../views/account/EditEmailView';
import { EditMfaView } from '../views/account/EditMfaView';
import { EditPurchaseContactView } from '../views/account/EditPurchaseContactView';
import { EditUserView } from '../views/account/EditUserView';
import { EmailValidationView } from '../views/account/EmailValidationView';
import { ContactDetailsView } from '../views/ContactDetailsView';
import { CreateListingView } from '../views/create-listing/CreateListingView';
import { CreateListingAddPickupAddressView } from '../views/create-listing/subviews/CreateListingAddPickupAddressView';
import { CreateListingChoosePickupAddressView } from '../views/create-listing/subviews/CreateListingChoosePickupAddressView';
import { CreateListingCreateView } from '../views/create-listing/subviews/CreateListingCreateView';
import { CreateListingSummaryView } from '../views/create-listing/subviews/CreateListingSummaryView';
import { CreateRFQView } from '../views/create-rfq/CreateRFQView';
import { FooCollectionView } from '../views/FooCollectionView';
import { FooCreateEntityView } from '../views/FooCreateEntityView';
import { FooEntityView } from '../views/FooEntityView';
import { ListingManagementView } from '../views/ListingManagementView';
import { ManageOffersView } from '../views/manage-offers/ManageOffersView';
import { MyAlertsView } from '../views/my-alerts/MyAlertsView';
import { MyAlertsCheckoutAddAddressView } from '../views/my-alerts/subviews/MyAlertsCheckoutAddAddressView';
import { MyAlertsCheckoutChooseAddressView } from '../views/my-alerts/subviews/MyAlertsCheckoutChooseAddressView';
import { MyAlertsCheckoutFormView } from '../views/my-alerts/subviews/MyAlertsCheckoutFormView';
import { MyAlertsListingDetailView } from '../views/my-alerts/subviews/MyAlertsListingDetailView';
import { MyAlertsOverviewView } from '../views/my-alerts/subviews/MyAlertsOverviewView';
import { MyAlertsSearchResultsView } from '../views/my-alerts/subviews/MyAlertsSearchResultsView';
import { MyAlertsSettingsView } from '../views/my-alerts/subviews/MyAlertsSettingsView';
import { UrgentRequestFormView } from '../views/my-alerts/UrgentRequestFormView';
import { MyCartView } from '../views/my-cart/MyCartView';
import { MyListingsDetailsView } from '../views/my-listings/MyListingsDetailsView';
import { MyListingsView } from '../views/my-listings/MyListingsView';
import { MyOffersView } from '../views/my-offers/MyOffersView';
import { MyOrdersDetailsView } from '../views/my-orders/MyOdersDetailsView';
import { MyOrdersView } from '../views/my-orders/MyOrdersView';
import { MyPackagesDetailsView } from '../views/my-packages/MyPackagesDetailsView';
import { MyRFQsDetailsView } from '../views/my-rfqs/MyRFQsDetailsView';
import { MyRFQsView } from '../views/my-rfqs/MyRFQsView';
import { PrivayPolicyView } from '../views/PrivacyPolicyView';
import { RFQManagementView } from '../views/RFQManagementView';
import { CheckoutAddAddressView } from '../views/search/CheckoutAddAddressView';
import { CheckoutChooseAddressView } from '../views/search/CheckoutChooseAddressView';
import { CheckoutFormView } from '../views/search/CheckoutFormView';
import { ListingDetailView } from '../views/search/ListingDetailView';
import { PackageDetailView } from '../views/search/PackageDetailView';
import { SearchFormView } from '../views/search/SearchFormView';
import { SearchListingsResultsView } from '../views/search/SearchListingsResultsView';
import { SearchListingsView } from '../views/search/SearchListingsView';
import { SearchPackagesResultsView } from '../views/search/SearchPackagesResultsView';
import { TermsAndConditionsView } from '../views/TermsAndConditionsView';
import { UrgentRequestsOverviewView } from '../views/urgent-requests/UrgentRequestsOverviewView';
import { UrgentRequestsView } from '../views/urgent-requests/UrgentRequestsView';

export const Router = () => {
    const { pathname } = useLocation();
    const analytics = useAnalytics();

    useEffect(() => {
        if (pathname !== '/') {
            analytics.sendEvent(TrackingEvent.PAGE_VIEW);
        }
    }, [pathname]);

    return (
        <Routes>
            <Route path={RoutePath.LOGIN} element={<Navigate to={RoutePath.DASHBOARD} />} />
            <Route path={RoutePath.ROOT} element={<Navigate to={RoutePath.DASHBOARD} />} />
            <Route path={RoutePath.FOOS} element={<FooCollectionView />} />
            <Route path={RoutePath.FOO} element={<FooEntityView />} />
            <Route path={RoutePath.CREATE_FOO} element={<FooCreateEntityView />} />
            <Route path={RoutePath.DASHBOARD} element={<Dashboard />} />
            <Route path={RoutePath.CREATE_RFQ} element={<CreateRFQView />}>
                <Route path={RoutePath.CREATE_DRAFT_RFQ_WIZARD} element={<CreateDraftRFQ />}>
                    <Route index element={<Step1 />} />
                    <Route path={RoutePath.CREATE_DRAFT_RFQ_STEP2} element={<Step2 />} />
                    <Route path={RoutePath.CREATE_DRAFT_RFQ_STEP3} element={<Step3 />} />
                </Route>
                <Route path={RoutePath.CREATE_RFQ_WIZARD} element={<CreateRFQ />}>
                    <Route index element={<Step1 />} />
                    <Route path={RoutePath.CREATE_RFQ_STEP2} element={<Step2 />} />
                    <Route path={RoutePath.CREATE_RFQ_STEP3} element={<Step3 />} />
                </Route>
                <Route path={RoutePath.CREATE_RFQ_SUMMARY} element={<CreateRFQSummary />} />
                <Route path={RoutePath.CREATE_RFQ_SUCCESS} element={<CreateRFQSuccess />} />
                <Route index element={<Navigate to={RoutePath.CREATE_RFQ_WIZARD} replace />} />
            </Route>
            <Route path={RoutePath.SEARCH_LISTINGS} element={<SearchListingsView />}>
                <Route index element={<SearchFormView />} />
                <Route path={RoutePath.SEARCH_LISTINGS_RESULTS} element={<SearchListingsResultsView />} />
                <Route path={RoutePath.PACKAGE_SEARCH_RESULTS} element={<SearchPackagesResultsView />} />
                <Route
                    path={RoutePath.SEARCH_LISTING_DETAILS}
                    element={
                        <ListingDetailView
                            checkoutPath={RoutePath.SEARCH_LISTINGS_CHECKOUT}
                            goBackPath={RoutePath.SEARCH_LISTINGS_RESULTS}
                            offerSuccessPath={RoutePath.SEARCH_LISTINGS_OFFER_SUCCESS}
                        />
                    }
                />
                <Route
                    path={RoutePath.PACKAGE_SEARCH_DETAILS}
                    element={
                        <PackageDetailView
                            checkoutPath={RoutePath.PACKAGE_SEARCH_CHECKOUT}
                            goBackPath={RoutePath.PACKAGE_SEARCH_RESULTS}
                        />
                    }
                />
                <Route path={RoutePath.SEARCH_LISTINGS_CHECKOUT} element={<CheckoutFormView />} />
                <Route path={RoutePath.PACKAGE_SEARCH_CHECKOUT} element={<CheckoutPackageFormView />} />
                <Route
                    path={RoutePath.SEARCH_LISTINGS_CHECKOUT_CHOOSE_ADDRESS}
                    element={<CheckoutChooseAddressView />}
                />
                <Route
                    path={RoutePath.PACKAGE_SEARCH_CHECKOUT_CHOOSE_ADDRESS}
                    element={<CheckoutChooseAddressView />}
                />
                <Route path={RoutePath.SEARCH_LISTINGS_CHECKOUT_ADD_ADDRESS} element={<CheckoutAddAddressView />} />
                <Route path={RoutePath.SEARCH_LISTINGS_OFFER_SUCCESS} element={<OfferSuccess />} />
            </Route>
            <Route path={RoutePath.RFQ_MANAGEMENT} element={<RFQManagementView />}>
                <Route index element={<Navigate replace to={RoutePath.MY_RFQS} />} />
                <Route path={RoutePath.MY_RFQS} element={<MyRFQsView />} />
                <Route
                    path={RoutePath.MY_RFQS_DETAILS}
                    element={<MyRFQsDetailsView goBackPath={RoutePath.MY_RFQS} />}
                />
                <Route path={RoutePath.MY_RFQS_DETAILS_SUCCESS} element={<RFQDetailsSuccess />} />
            </Route>

            <Route path={RoutePath.CONTACT_DETAILS} element={<ContactDetailsView />} />
            <Route path={RoutePath.PRIVACY_POLICY} element={<PrivayPolicyView />} />
            <Route path={RoutePath.ACCEPTABLE_USE_POLICY} element={<AcceptableUseView />} />
            <Route path={RoutePath.MY_ALERTS} element={<MyAlertsView />}>
                <Route index element={<MyAlertsOverviewView />} />
                <Route path={RoutePath.MY_ALERT_SETTINGS} element={<MyAlertsSettingsView />} />
                <Route path={RoutePath.MY_ALERTS_SEARCH} element={<MyAlertsSearchResultsView />} />
                <Route path={RoutePath.MY_ALERTS_LISTING_DETAILS} element={<MyAlertsListingDetailView />} />
                <Route path={RoutePath.MY_ALERTS_CHECKOUT} element={<MyAlertsCheckoutFormView />} />
                <Route
                    path={RoutePath.MY_ALERTS_CHECKOUT_CHOOSE_ADDRESS}
                    element={<MyAlertsCheckoutChooseAddressView />}
                />
                <Route path={RoutePath.MY_ALERTS_CHECKOUT_ADD_ADDRESS} element={<MyAlertsCheckoutAddAddressView />} />
                <Route path={RoutePath.MY_ALERTS_OFFER_SUCCESS} element={<OfferSuccess />} />
            </Route>
            <Route path={RoutePath.MANAGE_OFFERS} element={<ManageOffersView />}>
                <Route index element={<ManageOffers />} />
                <Route
                    path={RoutePath.MANAGE_OFFERS_DETAILS}
                    element={<MyListingsDetailsView goBackPath={RoutePath.MANAGE_OFFERS} />}
                />
            </Route>
            <Route path={RoutePath.MY_OFFERS} element={<MyOffersView />}>
                <Route index element={<MyOffers />} />
                <Route
                    path={RoutePath.MY_OFFERS_DETAILS}
                    element={
                        <ListingDetailView
                            checkoutPath={RoutePath.MY_OFFERS_CHECKOUT}
                            goBackPath={RoutePath.MY_OFFERS}
                            offerSuccessPath={RoutePath.MY_OFFERS_OFFER_SUCCESS}
                        />
                    }
                />
                <Route
                    path={RoutePath.MY_OFFERS_CHECKOUT}
                    element={
                        <CheckoutFormView
                            missingListingPath={RoutePath.MY_OFFERS}
                            goBackPath={RoutePath.MY_OFFERS_DETAILS}
                        />
                    }
                />
                <Route path={RoutePath.MY_OFFERS_CHECKOUT_CHOOSE_ADDRESS} element={<CheckoutChooseAddressView />} />
                <Route path={RoutePath.MY_OFFERS_CHECKOUT_ADD_ADDRESS} element={<CheckoutAddAddressView />} />
                <Route path={RoutePath.MY_OFFERS_OFFER_SUCCESS} element={<OfferSuccess />} />
            </Route>
            <Route path={RoutePath.IMMEDIATE_REQUEST} element={<UrgentRequestFormView />} />
            <Route path={RoutePath.LISTING_MANAGEMENT} element={<ListingManagementView />}>
                <Route index element={<Navigate replace to={RoutePath.MY_LISTINGS} />} />
                <Route path={RoutePath.CREATE_LISTING_WIZARD} element={<CreateListingView />}>
                    <Route index element={<CreateListingCreateView />} />
                    <Route
                        path={`${RoutePath.CREATE_LISTING_WIZARD}/:draftId/:listingType`}
                        element={<CreateListingCreateView key="draft" />}
                    />
                    <Route
                        path={RoutePath.CREATE_LISTING_CHOOSE_PICKUP_ADDRESS}
                        element={<CreateListingChoosePickupAddressView />}
                    />
                    <Route
                        path={RoutePath.CREATE_LISTING_WIZARD_OLD_URL}
                        element={<Navigate to={RoutePath.CREATE_LISTING_WIZARD} replace />}
                    />
                    <Route
                        path={RoutePath.CREATE_LISTING_ADD_PICKUP_ADDRESS}
                        element={<CreateListingAddPickupAddressView />}
                    />
                    <Route path={RoutePath.CREATE_LISTING_SUMMARY} element={<CreateListingSummaryView />} />
                </Route>
                <Route path={RoutePath.MY_LISTINGS} element={<MyListingsView />} />
                <Route
                    path={RoutePath.MY_LISTINGS_DETAILS}
                    element={<MyListingsDetailsView goBackPath={RoutePath.MY_LISTINGS} />}
                />
                <Route
                    path={RoutePath.MY_PACKAGES_DETAILS}
                    element={<MyPackagesDetailsView goBackPath={RoutePath.MY_PACKAGES} />}
                />
            </Route>
            <Route path={RoutePath.URGENT_REQUESTS} element={<UrgentRequestsView />}>
                <Route index element={<UrgentRequestsOverviewView />} />
                <Route path={RoutePath.URGENT_REQUESTS_SETTING} element={<UrgentRequestsSettings />} />
            </Route>
            <Route path={RoutePath.ACCOUNT} element={<AccountView />} />
            <Route path={RoutePath.ACCOUNT_EDIT_PURCHASE_CONTACT} element={<EditPurchaseContactView />} />
            <Route path={RoutePath.ACCOUNT_ADD_NEW_USER} element={<AddUserView />} />
            <Route path={RoutePath.ACCOUNT_EDIT_USER} element={<EditUserView />} />
            <Route path={RoutePath.ACCOUNT_EDIT_EMAIL} element={<EditEmailView />} />
            <Route path={RoutePath.ACCOUNT_CHANGE_PASSWORD} element={<ChangePasswordView />} />
            <Route path={RoutePath.ACCOUNT_EDIT_MFA} element={<EditMfaView />} />
            <Route path={RoutePath.ACCOUNT_EDIT_ADDRESS} element={<AccountEditAddressView />} />
            <Route path={RoutePath.ACCOUNT_ADD_ADDRESS} element={<AccountAddAddressView />} />
            <Route path={RoutePath.ACCOUNT_EMAIL_VALIDATION} element={<EmailValidationView />} />
            <Route path={RoutePath.TERMS_AND_CONDITIONS} element={<TermsAndConditionsView />} />
            <Route path={RoutePath.MY_ORDERS} element={<MyOrdersView />} />
            <Route path={RoutePath.MY_CART} element={<MyCartView />} />
            <Route path={RoutePath.MY_ORDERS_DETAILS} element={<MyOrdersDetailsView />} />
            <Route path={RoutePath.ACCOUNT_EDIT_PAYMENT_TERMS} element={<AccountEditPaymentTermsView />} />
            <Route path="*" element={<Navigate to={RoutePath.DASHBOARD} />} />
        </Routes>
    );
};
