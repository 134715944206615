import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './CardContentProductViewPrice.scss';

type NumberOfItems = {
    label: string;
    amount: number;
};

export type CardContentProductViewPriceProps = {
    header: string;
    pricePerUnit: string;
    offerPricePerUnit?: string;
    weight: string;
    totalExVat?: string;
    offerTotalExVat?: string;
    deliveryCosts?: string;
    vatPercent?: string;
    vat?: string;
    totalPrice?: string;
    offerTotalPrice?: string;
    numberOfItems?: NumberOfItems;
    pricePerUnitLabel: string;
    isAccepted?: boolean;
};

export const CardContentProductViewPrice = ({
    deliveryCosts,
    header,
    numberOfItems,
    pricePerUnit: pricePerTonne,
    offerPricePerUnit,
    pricePerUnitLabel,
    totalExVat,
    offerTotalExVat,
    totalPrice,
    offerTotalPrice,
    vatPercent,
    vat,
    weight,
    isAccepted = false,
}: CardContentProductViewPriceProps) => {
    const { t } = useTranslation('translation');

    const renderTotalExVat = () => {
        if (totalExVat === undefined || totalPrice === undefined) {
            return null;
        }
        return (
            <article className="product-view-price__content__pricing-overview__item">
                <span className="product-view-price__content__pricing-overview__item__label">
                    {t('application.checkout.totalExclVat')}
                </span>
                {isAccepted ? (
                    <span className="product-view-price__content__pricing-overview__item__value__offer">
                        <span className="product-view-price__content__pricing-overview__item__value__invalid">
                            {totalExVat}
                        </span>{' '}
                        {offerTotalExVat}
                    </span>
                ) : (
                    <span className="product-view-price__content__pricing-overview__item__value">{totalExVat}</span>
                )}
            </article>
        );
    };

    const renderDeliveryCosts = () => {
        if (deliveryCosts === undefined) {
            return null;
        }
        return (
            <article className="product-view-price__content__pricing-overview__item">
                <span className="product-view-price__content__pricing-overview__item__label">
                    {t('application.checkout.shippingCostsLabel', 'Delivery')}
                </span>
                <span className="product-view-price__content__pricing-overview__item__value">{deliveryCosts}</span>
            </article>
        );
    };

    const renderVat = () => {
        if (vat === undefined) {
            return null;
        }
        return (
            <article className="product-view-price__content__pricing-overview__item">
                <span className="product-view-price__content__pricing-overview__item__label">
                    {t('application.checkout.vatLabel', { value: vatPercent })}
                </span>
                <span
                    className={classNames({
                        'product-view-price__content__pricing-overview__item__value__offer': isAccepted,
                        'product-view-price__content__pricing-overview__item__value': !isAccepted,
                    })}
                >
                    {vat}
                </span>
            </article>
        );
    };

    const renderTotal = () => {
        if (totalPrice === undefined && totalExVat === undefined) {
            return null;
        }
        return (
            <article className="product-view-price__content__total-price">
                <span className="product-view-price__content__total-price__label">
                    {totalPrice !== undefined
                        ? t('application.checkout.totalInclVat')
                        : t('application.checkout.totalExclVat')}
                </span>
                {isAccepted ? (
                    <>
                        <span className="product-view-price__content__total-price__value">
                            <span className="product-view-price__content__total-price__value__invalid">
                                {totalPrice ?? totalExVat}
                            </span>
                        </span>
                        <span className="product-view-price__content__total-price__value">
                            <span className="product-view-price__content__total-price__value__price">
                                {offerTotalPrice ?? offerTotalExVat}
                            </span>
                        </span>
                    </>
                ) : (
                    <span className="product-view-price__content__total-price__value">
                        <span className="product-view-price__content__total-price__value__price">
                            {totalPrice ?? totalExVat}
                        </span>
                    </span>
                )}
            </article>
        );
    };

    return (
        <article className="product-view-price">
            <article className="product-view-price__header">{header}</article>
            <article className="product-view-price__content">
                <article className="product-view-price__content__pricing-overview">
                    <article className="product-view-price__content__pricing-overview__item">
                        <span className="product-view-price__content__pricing-overview__item__label">
                            {pricePerUnitLabel}
                        </span>
                        {isAccepted ? (
                            <span className="product-view-price__content__pricing-overview__item__value__offer">
                                <span className="product-view-price__content__pricing-overview__item__value__invalid">
                                    {pricePerTonne}
                                </span>{' '}
                                {offerPricePerUnit}
                            </span>
                        ) : (
                            <span className="product-view-price__content__pricing-overview__item__value">
                                {pricePerTonne}
                            </span>
                        )}
                    </article>
                    <article className="product-view-price__content__pricing-overview__item">
                        <span className="product-view-price__content__pricing-overview__item__label">
                            {t('application.checkout.weight', 'Weight')}
                        </span>
                        <span
                            className={classNames({
                                'product-view-price__content__pricing-overview__item__value__offer': isAccepted,
                                'product-view-price__content__pricing-overview__item__value': !isAccepted,
                            })}
                        >
                            {weight}
                        </span>
                    </article>
                    {numberOfItems && (
                        <article className="product-view-price__content__pricing-overview__item">
                            <span className="product-view-price__content__pricing-overview__item__label">
                                {numberOfItems.label}
                            </span>
                            <span
                                className={classNames({
                                    'product-view-price__content__pricing-overview__item__value__offer': isAccepted,
                                    'product-view-price__content__pricing-overview__item__value': !isAccepted,
                                })}
                            >
                                {numberOfItems.amount}
                            </span>
                        </article>
                    )}
                    {renderDeliveryCosts()}
                    {renderTotalExVat()}
                    {renderVat()}
                </article>
                {renderTotal()}
            </article>
        </article>
    );
};
