import { ReactNode } from 'react';
import { AddressIdentifier } from './CardContentAddressDetails.enums';
import { ButtonTertiaryOnLightM } from '../button-tertiary/button-tertiary-on-light-m/ButtonTertiaryOnLightM';
import { InputTextarea } from '../input-textarea/InputTextarea';

import './CardContentAddressDetails.scss';

interface CardContentAddressDetailsProps {
    heading: string;
    type: AddressIdentifier;
    attributeLabel?: string;
    additionalPlaceholder?: string;
    additionalText?: string;
    additionalTextLabel?: string;
    onChangeAdditionalText?: (value: string) => void;
    chooseOtherAddressButtonLabel?: string;
    onChooseOtherAddressButtonClick?: () => void;
    readonly?: boolean;
    children: ReactNode;
}

export const CardContentAddressDetails = ({
    additionalText,
    additionalTextLabel,
    children,
    chooseOtherAddressButtonLabel,
    heading,
    attributeLabel,
    onChangeAdditionalText,
    onChooseOtherAddressButtonClick,
    readonly,
    type,
    additionalPlaceholder,
}: CardContentAddressDetailsProps) => {
    const renderChooseOtherAddressButton = () => {
        if (readonly || type === AddressIdentifier.BILLING_ADDRESS) {
            return null;
        }

        return (
            <ButtonTertiaryOnLightM label={chooseOtherAddressButtonLabel} onClick={onChooseOtherAddressButtonClick} />
        );
    };

    const renderAdditionalTextInput = () => {
        if (type === AddressIdentifier.BILLING_ADDRESS) {
            return null;
        }

        return (
            <InputTextarea
                label={additionalTextLabel}
                onChange={onChangeAdditionalText}
                readOnly={readonly}
                value={additionalText}
                placeholder={additionalPlaceholder}
            />
        );
    };

    return (
        <article className="card-content-address-details">
            <article className="card-content-address-details__wrapper">
                <header className="card-content-address-details__wrapper__header">{heading}</header>
                {attributeLabel && (
                    <article className="card-content-address-details__wrapper__label">{attributeLabel}</article>
                )}
                <article className="card-content-address-details__wrapper__addressdata">
                    {children}
                    {renderChooseOtherAddressButton()}
                </article>
            </article>
            {renderAdditionalTextInput()}
        </article>
    );
};
