import { ForgotPassword } from '@steelbuy/ui-domain';
import { constants } from '../constants';
import { RoutePath } from '../router/Routes';

export const ForgotPasswordView = () => (
    <ForgotPassword
        apiBaseUrl={constants.apiBaseUrl}
        loginRoute={RoutePath.LOGIN}
        forgotPasswordRoute={RoutePath.FORGOT_PASSWORD}
    />
);
